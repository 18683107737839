<template>
  <ModalDialog
    :isShow="isShowUpdatePlanPopUp"
    :isClickOutside="false"
    @close="$emit('close')"
  >
    <template v-slot:description>
      <div class="update-plan-title">
        This feature is not available to users on the
        <span class="update-plan-title__plan-name">{{ planName }}</span
        >.
      </div>
      <div class="update-plan-description">
        If you would like to learn more about upgrading please contact
        <br />
        <a
          class="support-link"
          :href="`mailto:support@social-wonder.com?subject=${convertToFormattedString(
            subjectText
          )}`"
          >support@social-wonder.com</a
        >
      </div>
    </template>
    <template v-slot:footer_slot>
      <div class="plan-modal-footer">
        <div class="btn-modal">
          <button class="btn" @click="$emit('close')">OK</button>
        </div>
      </div>
    </template>
  </ModalDialog>
</template>

<script>
import ModalDialog from "@/components/global/ModalDialog.vue";

export default {
  name: "SmbUpdatePlanModal",
  components: { ModalDialog },
  props: {
    isShowUpdatePlanPopUp: {
      type: Boolean,
      default: false,
    },
    planName: {
      type: String,
      default: "",
    },
    subjectText: {
      type: String,
      default: "",
    },
  },
  methods: {
    convertToFormattedString(input) {
      return encodeURIComponent(input);
    },
  },
};
</script>

<style scoped lang="scss">
.update-plan-title {
  text-align: center;
  padding-bottom: 10px;
  font-size: 19px;
  font-weight: 700;
  color: #1d1d1b;
  &__plan-name {
    color: #70c5b0;
  }
}
.update-plan-description {
  font-weight: 400;
  color: #1d1d1b;
  text-align: center;
  font-size: 17px;
  .support-link {
    color: #1d1d1b;
  }
}
.plan-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .btn-modal {
    width: 100%;
    max-width: 120px;
    .btn {
      background: #92c4ff;
      width: 100%;
    }
  }
}
</style>
