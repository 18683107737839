<template>
  <div class="smb-client-wrapper">
    <Loader v-if="isLoading" />
    <template v-else>
      <SmbTitles
        is-show-title
        is-show-sub-title
        title="Listing"
        sub-title="Paste below the link to the Zillow page of the listing you want to promote or enter the property MLS number."
      />
      <div class="testimonials">
        <div class="testimonials__top-container">
          <div class="testimonials__title">Add listing below</div>
          <SmbInput
            :valueProp="listing"
            :isLoading="isInputLoading"
            :placeholder="
              isPropertiesClient
                ? 'Add @Properties/Zillow link/MLS # here'
                : 'Add Zillow link or MLS # here'
            "
            @propWasUpdated="propWasUpdatedHandler"
          />
        </div>
        <div class="design-type">
          <div class="design-type__title">Choose design type</div>
          <div class="design-type__content">
            <button
              class="design-type__button"
              :class="{
                active: selectedDesignType && selectedDesignType === item.id,
                'not-active':
                  selectedDesignType && selectedDesignType !== item.id,
              }"
              @click="setDesignType(item.id)"
              v-for="item in designType"
              :key="item.id"
            >
              <span class="design-type__button-title">{{ item.title }}</span>
              <span class="design-type__button-icon">
                <img :src="item.imgSrc" alt="" />
              </span>
            </button>
          </div>
        </div>

        <div class="visual" v-if="selectedDesignType === 2">
          <div class="visual__title">Choose visual</div>
          <div v-if="similarTemplatesList.length" class="visual__slider">
            <VueSlickCarousel v-bind="sliderSettings" ref="carousel">
              <div
                class="visual__item"
                v-for="item in similarTemplatesList"
                :key="item.id"
              >
                <button
                  class="visual__item-btn"
                  @click="setTemplate(item.id)"
                  :class="{
                    active: selectedVisual && selectedVisual === item.id,
                    'not-active':
                      hoveredVideo !== item.id &&
                      selectedVisual &&
                      selectedVisual !== item.id,
                  }"
                  @mouseover="hoveredVideo = item.id"
                  @mouseleave="hoveredVideo = null"
                >
                  <video
                    v-if="
                      selectedVisual === item.id || hoveredVideo === item.id
                    "
                    loop
                    autoplay
                  >
                    <source :src="item.preview_url" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <img v-else :src="item.snapshot_url" />
                </button>
              </div>
            </VueSlickCarousel>
            <button class="visual__slider-prev" @click="showNext">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="16"
                viewBox="0 0 11 16"
                fill="none"
              >
                <path
                  d="M8.82028 15.6834L0.278801 8.59103C0.177419 8.5066 0.105775 8.41513 0.0638707 8.31662C0.02129 8.21812 0 8.11258 0 8C0 7.88742 0.02129 7.78188 0.0638707 7.68338C0.105775 7.58487 0.177419 7.4934 0.278801 7.40897L8.82028 0.295514C9.05684 0.0985048 9.35253 0 9.70737 0C10.0622 0 10.3664 0.105541 10.6198 0.316623C10.8733 0.527704 11 0.773967 11 1.05541C11 1.33685 10.8733 1.58311 10.6198 1.7942L3.1682 8L10.6198 14.2058C10.8564 14.4028 10.9747 14.6454 10.9747 14.9336C10.9747 15.2224 10.8479 15.4723 10.5945 15.6834C10.341 15.8945 10.0453 16 9.70737 16C9.36943 16 9.07373 15.8945 8.82028 15.6834Z"
                  fill="#585757"
                ></path>
              </svg>
            </button>
            <button class="visual__slider-next" @click="showPrev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="16"
                viewBox="0 0 11 16"
                fill="none"
              >
                <path
                  d="M2.17972 0.316623L10.7212 7.40897C10.8226 7.4934 10.8942 7.58487 10.9361 7.68338C10.9787 7.78188 11 7.88742 11 8C11 8.11258 10.9787 8.21812 10.9361 8.31662C10.8942 8.41513 10.8226 8.5066 10.7212 8.59103L2.17972 15.7045C1.94316 15.9015 1.64747 16 1.29263 16C0.937788 16 0.63364 15.8945 0.380184 15.6834C0.126728 15.4723 0 15.226 0 14.9446C0 14.6631 0.126728 14.4169 0.380184 14.2058L7.8318 8L0.380184 1.7942C0.143625 1.59719 0.0253458 1.35458 0.0253458 1.06639C0.0253458 0.777626 0.152074 0.527704 0.40553 0.316623C0.658986 0.105541 0.954685 0 1.29263 0C1.63057 0 1.92627 0.105541 2.17972 0.316623Z"
                  fill="#585757"
                ></path>
              </svg>
            </button>
          </div>
          <div v-else>
            <Loader class="absolute-loader" />
          </div>
        </div>

        <div class="testimonials__bottom-text">
          We are working on adding support for more listing platforms/websites.
          <span v-if="!isPropertiesClient"
            >If you don’t have a Zillow account, you can use Custom Post to
            copy-paste your listing info.</span
          >
        </div>
      </div>
      <div class="footer-buttons">
        <SmbActionBtn
          class="arrow-button"
          isUseIcon
          iconName="mdi-arrow-left"
          width="20%"
          @click="$router.push({ name: 'generate-content' })"
        />
        <SmbActionBtn
          width="80%"
          :isLoading="isLoadingGenerateBtn"
          :text="isListingLink || isMls ? 'Generate' : 'Cancel'"
          :emitName="isListingLink || isMls ? 'generate' : 'cancel'"
          @cancel="$router.push({ name: 'generate-content' })"
          @generate="checkIsNeedToUpdateBranding"
        />
      </div>
    </template>
    <SmbErrorPopup
      :isShow="isShowCreationError"
      title="getPopupText"
      @close="closePopupHandler"
    >
      <template v-slot:error-description>
        <div class="error-description">
          <p>
            {{
              isMls
                ? "We couldn’t get all the listing data from the provided MLS number."
                : "We couldn’t get all the listing data from the provided link."
            }}
          </p>
          <p class="underline">No content was generated.</p>
        </div>
        <div class="blob-error-description smb-pt-10">
          <p>
            Please try adding a Zillow link (just for this listing) and
            generating again.
          </p>
        </div>
      </template>
    </SmbErrorPopup>
    <SmbAddBrandingDetailsPopUp
      :isShow="isShowBrandingPopUp"
      :actionType="isNeedToUpdateBranding"
      :buttonsData="{ listing: listing, type: typeOfCreationContent }"
      :emit-name="emitNameForActionAfterPopup"
      @close="isShowBrandingPopUp = false"
      @listingHandler="clickedOnSendListingBtnHandler()"
    />
    <SmbUpdatePlanModal
      :is-show-update-plan-pop-up="isShowUpdatePlanPopUp"
      plan-name="Basic Package"
      subject-text="Interested in learning more about upgrade"
      @close="isShowUpdatePlanPopUp = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { GENERATE_CONTENT_BUTTONS, SUBJECT_IDS } from "@/constants/smb-client";
import smbMethods from "@/mixins/smbMethods";
import H from "@/utils/helper";

import Loader from "@/components/global/Loader";
import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbActionBtn from "@/components/smb-client/SmbActionBtn";
import SmbInput from "@/components/smb-client/SmbInput";
import SmbAddBrandingDetailsPopUp from "@/components/smb-client/SmbAddBrandingDetailsPopUp.vue";
import LogRocket from "logrocket";
import SmbErrorPopup from "@/components/smb-client/SmbErrorPopup.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import SmbUpdatePlanModal from "@/components/smb-client/SmbUpdatePlanModal.vue";

export default {
  name: "Listing",
  data: () => ({
    isLoading: false,
    isLoadingGenerateBtn: false,
    isInputLoading: false,
    listingSubjectId: SUBJECT_IDS.LISTING.GENERAL,
    isShowBrandingPopUp: false,
    emitNameForActionAfterPopup: "",
    listing: "",
    isShowCreationError: false,
    selectedDesignType: null,
    selectedVisual: null,
    hoveredVideo: null,
    similarTemplatesList: [],
    sliderSettings: {
      dots: false,
      slidesToShow: 6,
      slidesToScroll: 2,
      arrows: false,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    },
    isShowUpdatePlanPopUp: false,
  }),
  mixins: [smbMethods],
  computed: {
    ...mapGetters("creationContentType", ["getCurrentCreationContentType"]),
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    isListingLink() {
      return !!this.isLink(this.listing);
    },
    isMls() {
      return !!this.isMlsNumber(this.listing);
    },
    popUpTextFirstPath() {
      return `The new listing ${this.getCurrentCreationContentType}  is now being generated.`;
    },
    popUpTextSecondPath() {
      return this.getCurrentSmbCustomer.primary_phone
        ? `You will be notified via SMS to ${H.formatPhoneNumber(
            this.getCurrentSmbCustomer.primary_phone,
            false
          )} once ready (about 1 min).`
        : "You can check it out in the Pending content section in ~30 seconds.";
    },
    popUpText() {
      return `${this.popUpTextFirstPath} ${this.popUpTextSecondPath}`;
    },
    isRedirectedFromGeneratedPage() {
      return this.$route.params && this.$route.params.isFromGenerated;
    },
    typeOfCreationContent: {
      get() {
        return this.getCurrentCreationContentType;
      },
      set(newType) {
        this.setCurrentCreationContentType(newType);
      },
    },
    isPropertiesClient() {
      const regex = /@atproperties\b/;
      return (
        this.getCurrentSmbCustomer.is_atproperties ||
        regex.test(this.getCurrentSmbCustomer.mail)
      );
    },
    designType() {
      if (this.getCurrentCreationContentType === "post") {
        return [
          {
            id: 1,
            title: "Carousel",
            imgSrc: require("@/assets/img/saas/listing/design_type/carousel.png"),
          },
          {
            id: 2,
            title: "Reel (video)",
            imgSrc: require("@/assets/img/saas/listing/design_type/reel-video.png"),
          },
        ];
      } else {
        return [
          {
            id: 1,
            title: "Image",
            imgSrc: require("@/assets/img/saas/listing/design_type/img_b_story.png"),
          },
          {
            id: 2,
            title: "Video",
            imgSrc: require("@/assets/img/saas/listing/design_type/video.png"),
          },
        ];
      }
    },
  },
  components: {
    SmbUpdatePlanModal,
    SmbErrorPopup,
    Loader,
    SmbTitles,
    SmbActionBtn,
    SmbInput,
    SmbAddBrandingDetailsPopUp,
    VueSlickCarousel,
  },
  async created() {
    if (
      this.$route.params &&
      this.$route.params.emitName &&
      !H.isEmpty(this.$route.params.buttonsData)
    ) {
      let { listing, type = "post" } = this.$route.params.buttonsData;
      this.listing = listing;
      this.typeOfCreationContent = type;
      await this.clickedOnSendListingBtnHandler();
    }
    await this.getTemplatesList();

    if (
      this.getCurrentCreationContentType === "post" &&
      this.getCurrentSmbCustomer.plan === 1
    ) {
      this.selectedDesignType = 1;
    }
  },
  methods: {
    ...mapMutations("smbClientSelection", ["setPopupSettings"]),
    ...mapActions("smbClientSelection", ["createPosts", "fetchSmbClientInfo"]),
    ...mapMutations("creationContentType", ["setCurrentCreationContentType"]),
    ...mapActions("templates", ["getTemplatesInfo"]),
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
    isLink(text) {
      const expression =
        /https?:\/\/(www\.)?(zillow|atproperties)\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;

      const regex = new RegExp(expression);
      return !!text.match(regex);
    },
    isMlsNumber(text) {
      const expression = /^[0-9]*$/gi;

      const regex = new RegExp(expression);
      return !!text.match(regex) && text.length >= 4;
    },
    setTemplate(templateId) {
      this.selectedVisual === templateId
        ? (this.selectedVisual = null)
        : (this.selectedVisual = templateId);
    },
    propWasUpdatedHandler(value) {
      this.listing = value;
    },
    async checkIsNeedToUpdateBranding() {
      if (this.isNeedToUpdateBranding) {
        this.isShowBrandingPopUp = true;
        this.emitNameForActionAfterPopup = "listingHandler";
      } else {
        await this.clickedOnSendListingBtnHandler();
      }
    },
    async clickedOnSendListingBtnHandler() {
      if (this.isListingLink || this.isMls) {
        try {
          LogRocket.track("content_generation_button");
          if (this.getCurrentCreationContentType === "post") {
            LogRocket.track("generate_content_video_post");
          } else {
            LogRocket.track("generate_content_video_story");
          }
          this.isInputLoading = true;
          this.isLoadingGenerateBtn = true;
          this.isShowBrandingPopUp = false;

          const params = {
            customerId: this.getCurrentSmbCustomer.id,
            mls_link: this.isMls
              ? "https://www.atproperties.com/" + this.listing + "/MLSAPI"
              : this.listing,
            topicSubId: [this.listingSubjectId],
            bySmb: true,
            type: this.getCurrentCreationContentType,
            templateId:
              this.selectedDesignType === 2
                ? this.selectedVisual
                  ? this.selectedVisual
                  : this.similarTemplatesList[0].id
                : undefined,
          };

          const response = await this.createPosts(params);
          if (response) {
            await this.setPopupSettings({
              isShowPopup: true,
              popUpText: this.popUpText,
            });
            this.listing = "";
          } else {
            this.isShowCreationError = true;
          }
        } catch (e) {
          console.error(e);
          throw e;
        } finally {
          this.isInputLoading = false;
          this.isLoadingGenerateBtn = false;
        }
      }
    },

    async getTemplatesList() {
      let params = {
        customerId: this.getCurrentSmbCustomer.id,
        currentTemplateId: "d31346de-4830-42bc-8baa-d59798c062d1",
      };
      let { similarTemplates } = await this.getTemplatesInfo(params);
      this.similarTemplatesList = similarTemplates.sort(
        () => Math.random() - 0.5
      );
    },
    setDesignType(id) {
      if (
        this.getCurrentCreationContentType === "post" &&
        this.getCurrentSmbCustomer.plan === 1 &&
        id === 2
      ) {
        this.isShowUpdatePlanPopUp = true;
      } else {
        this.selectedVisual = null;
        this.selectedDesignType === id
          ? (this.selectedDesignType = null)
          : (this.selectedDesignType = id);
      }
    },
    closePopupHandler() {
      this.listing = "";
      this.isShowCreationError = false;
    },
  },
};
</script>

<style scoped lang="scss">
.smb-client-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f3f2f3;
  height: 100%;

  .testimonials {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    flex: 1;

    &__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }

    &__top-container {
      max-width: 500px;
      width: 100%;
    }

    &__bottom-text {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      text-align: center;
      margin-top: auto;
    }
  }

  .visual {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 250px;

    &__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 15px;
    }

    &__slider {
      position: relative;
      &-prev {
        outline: none;
        display: inline-flex;
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }
      &-next {
        outline: none;
        display: inline-flex;
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__item-btn {
      width: 90px;
      aspect-ratio: 9 / 16;
      border-radius: 5px;
      border: 1px solid #000;
      display: inline-flex;
      overflow: hidden;

      &.not-active {
        opacity: 0.3;
      }

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: contain;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .absolute-loader {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .design-type {
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;

    &__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 15px;
    }

    &__content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(220px, 220px));
      grid-gap: 28px;
      @media screen and (max-width: 420px) {
        justify-content: center;
      }
    }

    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 16px 6px;
      border-radius: 7px;
      background: #fff;
      border: 1px solid #000;
      cursor: pointer;

      &-title {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
      }

      &-icon {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 115px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      &:hover,
      &.active {
        background-color: #92c4ff;
      }

      &.not-active {
        opacity: 0.3;

        &:hover {
          background: #fff;
        }
      }
    }
  }

  .footer-buttons {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    position: relative;
    @media screen and (min-width: 760px) {
      align-items: center;
      justify-content: center;
      padding-bottom: 30px;
    }

    .button-item {
      width: 100%;
      @media screen and (min-width: 760px) {
        width: 100%;
        max-width: 315px;
      }
    }

    .arrow-button {
      width: 20%;
      position: absolute;
      left: 0;
      z-index: 10;

      ::v-deep {
        .text {
          display: none;
        }
      }

      @media screen and (min-width: 760px) {
        ::v-deep {
          left: 10px;
          width: 120px !important;

          button {
            background: transparent !important;
            color: #1d1d1b !important;
            box-shadow: none;
            width: 120px !important;

            &:before {
              display: none;
            }

            .v-ripple__container {
              display: none !important;
            }

            .text {
              display: inline-flex;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}

::v-deep {
  .custom-arrow {
    &:before {
      display: none;
    }
  }
}
</style>
