<template>
  <div>
    <v-dialog
      content-class="smb-error-popup"
      v-model="isShow"
      persistent
      activator="parent"
      width="500"
    >
      <v-card class="smb-popup">
        <v-card-title class="text-h5"> An error occoccurred </v-card-title>

        <v-card-text>
          <slot name="error-description"></slot>
        </v-card-text>

        <v-card-actions class="smb-popup__actions">
          <v-btn
            class="smb-popup__button"
            color="#fe3a43"
            @click="$emit('close', isChecked)"
            >OK</v-btn
          >
          <v-checkbox
            v-if="isCanSeeDemoFeatures"
            v-model="isChecked"
            color="black"
            label="don't show me this message again"
          ></v-checkbox>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SmbErrorPopup",
  data: () => ({
    isChecked: false,
  }),
  computed: {
    ...mapGetters("smbClientSelection", ["isCanSeeDemoFeatures"]),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    isShowCheckbox: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .smb-error-popup {
    border-radius: 12px;
    .smb-popup {
      &__button {
        color: #ffffff;
        width: 100px;
        border-radius: 16px;
      }
      &__actions {
        display: flex;
        flex-direction: column;
        ::v-deep {
          .v-input--selection-controls {
            label {
              font-weight: 400;
              font-size: 12px;
            }
          }
        }
      }
      .v-card__title {
        background: #fe3a43;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        line-height: 140%;
        word-break: break-word;
      }
      .v-card__text {
        padding: 13px 23px 11px 23px;
        .error-description {
          color: #1d1d1b;
          font-size: 12px;

          .underline {
            text-decoration: underline;
          }
        }
        .blob-error-description {
          color: #1d1d1b;
          font-size: 12px;
          font-weight: 700;
        }
        .smb-pt-10 {
          padding-top: 10px;
        }
      }
    }
  }
}

::v-deep {
  @media screen and (min-width: 760px) {
    .smb-error-popup {
      .smb-popup {
        .v-card__title {
          font-size: 24px;
          padding: 9px 62px 15px 12px;
        }
        .error-description,
        .blob-error-description {
          font-size: 16px !important;
          line-height: 192%;
        }
      }
    }
  }
}
</style>
